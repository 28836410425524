dmx.Component('autocomplete', {

  extends: 'form-element',

  initialData: {
    selectedItem: null,
  },

  attributes: {
    data: {
      type: Array,
      default: [],
    },

    matchcase: {
      type: Boolean,
      default: false,
    },

    matchstart: {
      type: Boolean,
      default: false,
    },

    matchaccent: {
      type: Boolean,
      default: false,
    },

    optiontext: {
      type: String,
      default: '$value',
    },

    optionvalue: {
      type: String,
      default: '$value',
    },

    optionsearch: {
      type: String,
      default: null,
    },

    noresultslabel: {
      type: String,
      default: 'No Results...',
    },

    placeholder: {
      type: String,
      default: '',
    },

    noclear: {
      type: Boolean,
      default: false,
    },
  },

  events: {
    noresults: Event,
  },

  init (node) {
    dmx.Component('form-element').prototype.init.call(this, node);

    this._keydownHandler = this._keydownHandler.bind(this);
    this._mousedownHandler = this._mousedownHandler.bind(this);

    this._data = [];
    this._selectedIndex = -1;
  },

  destroy () {
    dmx.Component('form-element').prototype.destroy.call(this);

    this._input.removeEventListener('keydown', this._keydownHandler);
    this._list.removeEventListener('mousedown', this._mousedownHandler);
    this._hideList(true);
    this._input.remove();
    this.$node.style.removeProperty('display');
  },

  render (node) {
    this._input = node.cloneNode();
    this._input.removeAttribute('id');
    this._input.removeAttribute('name');
    this._input.setAttribute('form', '__dummy__');
    this._input.setAttribute('autocomplete', 'off');
    this._input.addEventListener('keydown', this._keydownHandler);
    this._input.addEventListener('input', this._inputHandler);
    this._input.addEventListener('change', this._changeHandler);
    this._input.addEventListener('focus', this._focusHandler);
    this._input.addEventListener('blur', this._blurHandler);

    node.style.setProperty('display', 'none');
    node.parentNode.insertBefore(this._input, node);

    this._list = document.createElement('div');
    this._list.setAttribute('id', this.name + '-list');
    this._list.classList.add('dmx-autocomplete-items');
    this._list.addEventListener('mousedown', this._mousedownHandler);

    // Aria
    this._input.setAttribute('role', 'combobox');
    this._input.setAttribute('aria-autocomplete', 'list');
    this._input.setAttribute('aria-controls', this.name + '-list');
    this._input.setAttribute('aria-expanded', 'false');

    this._list.setAttribute('role', 'listbox');
  },

  performUpdate (updatedProps) {
    if (updatedProps.has('data') || updatedProps.has('optiontext') || updatedProps.has('optionvalue') || updatedProps.has('optionsearch')) {
      this._data = dmx.repeatItems(this.props.data).map((item, index) => {
        const label = dmx.parse(this.props.optiontext, dmx.DataScope(item, this));
        const value = dmx.parse(this.props.optionvalue, dmx.DataScope(item, this));
        const search = this.props.optionsearch ? dmx.parse(this.props.optionsearch, dmx.DataScope(item, this)) : label;
        return { index, label, value, search, item };
      });
    }

    dmx.Component('form-element').prototype.performUpdate.call(this, updatedProps);
  },
  
  _setValue (value, defaultValue) {
    const selectedItem = this._data.find(item => item.value == value);

    this._input.value = selectedItem ? selectedItem.label : '';
    this.$node.value = selectedItem ? selectedItem.value : '';

    if (defaultValue) {
      this._input.defaultValue = this._input.value;
      this.$node.defaultValue = this.$node.value;
    }

    this.set('value', this.$node.value);
    this.dispatchEvent('updated');
  },

  _focus () {
    this._input.focus();
  },

  _disable (disable) {
    this._input.disabled = disable;
    dmx.Component('form-element').prototype._disable.call(this, disable);
  },

  _updateList () {
    const value = this._input.value;

    let i = 0;
    let html = '';

    if (value) {
      for (const item of this._data) {
        if (this._matches(item.search, value)) {
          html += `<div id="${this.name}-option-${++i}" class="dmx-autocomplete-item" value="${this._htmlEncode(item.value)}" role="option">${this._htmlItem(item.label, value)}</div>`;
        }
      }
    }

    this._selectedIndex = -1;
    this._list.innerHTML = html;

    if (value) {
      this._showList();
    } else {
      this._hideList();
    }
  },

  _updateActive () {
    const nodes = this._list.children;

    if (this._selectedIndex >= nodes.length) this._selectedIndex = 0;
    if (this._selectedIndex < 0) this._selectedIndex = nodes.length - 1;

    Array.from(nodes).forEach((node, index) => {
      if (index === this._selectedIndex) {
        node.classList.add('dmx-autocomplete-active');
        node.setAttribute('aria-selected', 'true');
        if (node.id) {
          this._input.setAttribute('aria-activedescendant', node.id);
        }
      } else {
        node.classList.remove('dmx-autocomplete-active');
        node.removeAttribute('aria-selected');
      }
    });

    const itemTop = nodes[this._selectedIndex].offsetTop;
    const itemHeight = nodes[this._selectedIndex].offsetHeight;
    const listTop = this._list.scrollTop;
    const listHeight = this._list.offsetHeight;

    if (itemTop < listTop) {
      this._list.scrollTop = itemTop;
    } else if (itemTop + itemHeight > listTop + listHeight) {
      this._list.scrollTop = itemTop + itemHeight - listHeight;
    }
  },

  _htmlItem (str, value) {
    let html = this._htmlEncode(str);
    let re = new RegExp((this.props.matchstart ? '^' : '') + dmx.escapeRegExp(this._htmlEncode(value), this.props.matchcase ? 'g' : 'gi'));
    return html.replace(re, m => `<b>${m}</b>`);
  },

  _htmlEncode (str) {
    return String(str).replace(/&/g, '&amp;').replace(/"/g, '&quot;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
  },

  _matches (search, value) {
    if (!search) return false;

    if (!this.props.matchaccent) {
      search = this._normalize(search);
      value = this._normalize(value);
    }

    if (!this.props.matchcase) {
      search = search.toLowerCase();
      value = value.toLowerCase();
    }

    if (this.props.matchstart) {
      search = search.substring(0, value.length);
    }

    return search.includes(value);
  },

  _normalize (str) {
    if (str.normalize) {
      return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }

    const TAB_00C0 = 'AAAAAAACEEEEIIIIDNOOOOO*OUUUUYIsaaaaaaaceeeeiiii?nooooo/ouuuuy?yAaAaAaCcCcCcCcDdDdEeEeEeEeEeGgGgGgGgHhHhIiIiIiIiIiJjJjKkkLlLlLlLlLlNnNnNnnNnOoOoOoOoRrRrRrSsSsSsSsTtTtTtUuUuUuUuUuUuWwYyYZzZzZzF';
    const result = str.split('');

    for (const i = 0; i < result.length; i++) {
      const c = str.charCodeAt(i);
      if (c >= 0x00c0 && c <= 0x017f) {
        result[i] = String.fromCharCode(TAB_00C0.charCodeAt(c - 0x00c0));
      } else if (c > 127) {
        result[i] = '?';
      }
    }

    return result.join('');
  },

  _showList () {
    if (this._list.innerHTML === '') return;
    this._list.style.setProperty('left', this._input.offsetLeft + 'px');
    this._list.style.setProperty('top', (this._input.offsetTop + this._input.offsetHeight) + 'px');
    if (!this._list.parentNode) {
      this.$node.parentNode.insertBefore(this._list, this.$node);
      this._input.setAttribute('aria-expanded', 'true');
    }
  },

  _hideList (clear) {
    this._list.remove();
    this._input.setAttribute('aria-expanded', 'false');
    this._input.setAttribute('aria-activedescendant', '');
    if (clear) {
      this._list.innerHTML = '';
    }
  },

  _keydownHandler (event) {
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        this._selectedIndex++;
        this._updateActive();
        this._showList();
        break;

      case 'ArrowUp':
        event.preventDefault();
        this._selectedIndex--;
        this._updateActive();
        this._showList();
        break;

      case 'Escape':
        event.preventDefault();
        this._selectedIndex = -1;
        this._hideList();
        break;

      case 'Enter':
        event.preventDefault();
        if (this._selectedIndex > -1) {
          this._list.children[this._selectedIndex].dispatchEvent(new MouseEvent('mousedown', { bubbles: true }));
        }
        break;
    }
  },

  _mousedownHandler (event) {
    event.preventDefault();
    event.stopPropagation();
    
    this._setValue(event.target.getAttribute('value'));
    this._input.select();
    this._hideList();
  },

  _inputHandler (event) {
    this._updateList();
  },

  _focusHandler (event) {
    this._input.select();

    this._showList();

    dmx.Component('form-element').prototype._focusHandler.call(this, event);
  },

  _blurHandler (event) {
    const found = this._data.find(item => item.label == this._input.value);

    if (!found) {
      if (this.props.noclear) {
        this.$node.value = this._input.value;
      } else {
        this._setValue('');
      }
    }

    this._hideList(true);

    dmx.Component('form-element').prototype._blurHandler.call(this, event);
  },

  _resetHandler (event) {
    this._input.value = this._input.defaultValue;
    this.$node.value = this.$node.defaultValue;
    dmx.Component('form-element').prototype._resetHandler.call(this, event);
  },

});
